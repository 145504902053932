import React from "react";
import { Link } from "react-router-dom";
import Box from "../Box/Box";

const LinkTo = ({ to = "", children, ...props }) => {
  const defaultLinkTo = `${window.location.pathname}${window.location.search}`;

  if (window.location.pathname === to) {
    return (
      <Box as="a" onClick={() => window.scrollTo({ top: 0, behavior: "smooth" })}>
        {children}
      </Box>
    );
  }

  if (to.indexOf("http", 0) !== -1) {
    return (
      <a
        href={to || defaultLinkTo}
        target="_blank"
        rel="noopener noreferrer"
        {...props}
      >
        {children}
      </a>
    );
  }
  return (
    <Link to={to || defaultLinkTo} {...props}>
      {children}
    </Link>
  );
};

export default LinkTo;
