import React from "react";
import PropTypes from "prop-types";
import { Col, Row } from "antd";
import { FaCcAmex, FaCcMastercard, FaCcVisa } from "react-icons/fa";

import { useBaseData } from "context/BaseDataProvider";
import Box from "components/UI/Box/Box";
import Heading from "components/UI/Heading/Heading";
import Text from "components/UI/Text/Text";
import Logo from "components/UI/Logo/Logo";
import FooterMenu from "./FooterMenu";
import LinkTo from "components/UI/LinkTo/LinkTo";
import { HOME_PAGE } from "settings/constant";

const Footer = () => {
  const {
    preferences: { fullWhiteLogoUrl, primaryAddress, primaryColor },
  } = useBaseData();

  return (
    <Box p={[4,4,4,5]} bg="#111111" color="white">
      <Row gutter={24}>
        <Col xs={24}>
          <Box flexBox flexDirection="column" mb={[4,4,4,5]} alignItems="center">
            <Text
              mb={3}
              fontWeight={700}
              fontSize={[24,40,50,60,80]}
              content={`find your style right now`}
              textAlign="center"
            />
            <LinkTo to={HOME_PAGE}>
              <Box
                bg={primaryColor}
                py={[2,2,3,3]}
                px={[3,3,4,4]}
                borderRadius={30}
                color="white"
              >
                Shop Now
              </Box>
            </LinkTo>
          </Box>
        </Col>
      </Row>
      <Row gutter={24}>
        <Col xs={24} lg={5}>
          <Box mt={2}>
            <Logo withLink linkTo="/" src={fullWhiteLogoUrl} />
            <Text color="white" mt={3} content={primaryAddress} />
          </Box>
        </Col>
        <Col xs={24} lg={14}>
          <FooterMenu />
        </Col>
        <Col xs={24} lg={5}>
          <Heading color="white" content="Security & Payment" mt={[3, 3, 3, 0]} mb={[1, 1, 1, 0]} />
          <Text
            color="white"
            content="We are secured by 128 bit SSL making your payments secure"
          />
          <Text color="white" mt={2} content="We accept" />
          <Box as="ul" flexBox flexDirection="row">
            <Box>
              <FaCcVisa fontSize={24} />
            </Box>
            <Box ml={2}>
              <FaCcMastercard fontSize={24} />
            </Box>
            <Box ml={2}>
              <FaCcAmex fontSize={24} />
            </Box>
          </Box>
        </Col>
      </Row>
      <Box borderTop="1px solid #f6f6f6" width="100%" my={4} />
      <Row gutter={24}>
        <Col xs={24}>
          <Text
            content={`© ${new Date().getFullYear()} Diskounto Group. All rights reserved`}
            textAlign="center"
            fontWeight={300}
            fontSize={["7px", "8px", "9px", "14px"]}
          />
        </Col>
      </Row>
    </Box>
  );
};

Footer.propTypes = {
  className: PropTypes.string,
  logo: PropTypes.element,
  menu: PropTypes.element,
  bgSrc: PropTypes.string,
  copyright: PropTypes.oneOfType([PropTypes.string, PropTypes.element]),
};

export default Footer;
